import React, { useEffect } from "react";
import NavBarItems from "./NavBarItems";
import LogoutButton from "./LogoutButton";
import { Offcanvas, initTE } from 'tw-elements';

import '../../scss/OffCanvas.scss';
import SettingsButton from "./SettingsButton";

export default function OffCanvasMenu() {
  useEffect(() => {
    initTE({ Offcanvas })
  }, [])

  return (
    <div
      className="invisible fixed bottom-0 right-0 top-0 z-[1045] flex w-3/4 max-w-full translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out dark:bg-neutral-800 dark:text-neutral-200 [&[data-te-offcanvas-show]]:transform-none offcanvas-menu"
      tabIndex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
      data-te-offcanvas-init
    >
      <div className="offcanvas-header h-1/6 flex p-4 justify-end">
        <button
        type="button"
        className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none  focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
        data-te-offcanvas-dismiss
        aria-label="Close">
          <span
            className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-6 w-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12" />
            </svg>
          </span>
        </button>
      </div>
      <div className="offcanvas-body h-2/3 flex-grow overflow-y-auto p-4 items-center">
        <NavBarItems />
      </div>
      <div className="offcanvas-footer h-1/4 flex-grow overflow-y-auto p-2 md:p-4">
        <div className="flex items-center justify-center">
          <SettingsButton />
          <LogoutButton />
        </div>
      </div>
    </div>
  )
}