import  { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import UserService from "./services/UserService";

function GuardedRoute(props) {
  const navigate = useNavigate();
  const isIntroducer = UserService.isIntroducer();
  const isSolicitor = UserService.isSolicitor();
  const isRZClientServices = UserService.isClientServices();
  const isBDM = UserService.isBDM();
  const isClient = props.isClient;

  useEffect(() => {
    if ((isClient && isIntroducer && isSolicitor && isBDM) || (!isClient && !isIntroducer && !isSolicitor && !isBDM))
        return navigate("/not-authorized");
  }, [navigate, isClient, isIntroducer, isSolicitor, isBDM]);

  if ((isClient && !isIntroducer) || (!isClient && isIntroducer) || (!isClient && isSolicitor) || (!isClient && isRZClientServices) || (!isClient && isBDM)) {
      return props.children;
  }

  return null;
}

export default GuardedRoute;

