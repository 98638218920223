// ReasonModal.jsx

import React from 'react';
import './ModalStyles.scss';

function ReasonModal({ visible, onSubmit, onCancel, reasonText, setReasonText }) {
    if (!visible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <h2>Enter Reason</h2>
                <textarea
                    value={reasonText}
                    onChange={(e) => setReasonText(e.target.value)}
                    placeholder="Please enter a reason"
                />
                <div className="modal-buttons">
                    <button className="cancel-btn" onClick={onCancel}>Cancel</button>
                    <button className="proceed-btn" onClick={onSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default ReasonModal;
