// ConfirmationModal.jsx

import React from 'react';
import './ModalStyles.scss';

function ConfirmationModal({ visible, onProceed, onCancel, message }) {
    if (!visible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <h2>Confirm</h2>
                <p>{message}</p>
                <div className="modal-buttons">
                    <button className="cancel-btn" onClick={onCancel}>Cancel</button>
                    <button className="proceed-btn" onClick={onProceed}>Proceed</button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationModal;
