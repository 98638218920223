import React from "react";
import { NavLink } from "react-router-dom";
import NavBarItems from "./NavBarItems";
import LogoutButton from "./LogoutButton";
import SettingsButton from "./SettingsButton";
import 'tw-elements';

import logo from "../../RedZed_Logo_Red.svg";
import '../../scss/NavBar.scss';

export default function NavBar(props) {
  return(
    <nav
      id="main-navbar"
      className="flex w-full flex-wrap items-center justify-between bg-[#FBFBFB]"
      data-te-navbar-ref
    >
      <div className="nav-container container-fluid w-full flex flex-wrap items-center justify-between py-2 px-2 md:px-4 z-40">
        <NavLink className="flex items-center" to="/"><img src={logo} className="App-logo" alt="logo" /></NavLink>
        <button
          className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
          type="button"
          data-te-offcanvas-toggle
          data-te-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" className="w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
          </svg>
        </button>
        <div className="!visible mt-2 flex-grow basis-auto items-center lg:mt-0 hidden lg:!flex lg:basis-auto">
          <NavBarItems
            userInfo={props.userInfo}
          />
        </div>
        <div className="xs:hidden md:flex justify-end items-center flex flex-wrap basis-1/3">
          <SettingsButton />
          <LogoutButton />
        </div>
      </div>
    </nav>
  );
}

