import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const defaultPosition = toast.POSITION.BOTTOM_RIGHT;

/**
 * Show Toast
 *
 * Display toast
 *
 * @param {string} type message type, success/error
 * @param {string} message toast message
 * @param {integer} autoClose auto close value in millisecond
 * @param {string} position toast position; ex-'top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left'
 */

export const showSuccess = (
  message,
  autoClose = 8000,
  position = defaultPosition
) => {
  toast.success(message, {
    autoClose: autoClose === null ? 2000 : autoClose,
    className: "primaryColor",
    position: position,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const showError = (
  message,
  autoClose = 8000,
  position = defaultPosition
) => {
  toast.error(message, {
    autoClose: autoClose === null ? 2000 : autoClose,
    className: "dangerColor",
    position: position,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
