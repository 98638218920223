import moment from "moment";

// Convert string to pascal casing...
export const toPascalCase = (str) => {
  return str.replace(/\w\S*/g, function (t) {
    return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
  });
};

export const dateFormatter = (params, format) => {
  // eslint-disable-next-line no-unused-vars
  const [dateOnly, timeOnly] = params.split(' ');
  const [year, month, day] = dateOnly.split('/');
  const isoDate = new Date(year + '-' + month + '-' + day);
  const date = moment(isoDate);

  if (date._isValid) {
    if (format === 'short') {
      return date.format('DD/MM/YYYY');
    }

    if (format === 'full') {
      return date.format('LL');
    }
  }
};

export const currencyFormatterNoDecimals = (input) => {

  const absFormatted = Math.abs(input);
  const sansDec = absFormatted.toFixed(0);
  const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return '$' + formatted;
}

export const currencyFormatter = (input, appendTerm) => {
  const formatAmount = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: 'AUD',
    maximumFractionDigits: 2,
    roundingIncrement: 1,
  })

  return formatAmount.format(input)
}

export const transactionFormatter = (input, type) => {
  const absFormatted = Math.abs(input);
  const sansDec = absFormatted.toFixed(2);
  const formatted = [sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",")]

  if (type === 'P') {
    return '+' + formatted
  } else {
    return '-' + formatted
  }
}

export const dateDifference = (date, format) => {
  const today = new Date();
  const currentDate = moment(today).format('YYYY, MM, DD');
  const [cYear, cMonth, cDay] = currentDate.split(',')
  const [day, month, year] = date.split('/');
  const tDate = moment([cYear, cMonth, cDay]);
  const diffDate = moment([year, month, day]);

  if (tDate._isValid && diffDate._isValid) {
    return diffDate.diff(tDate, format) + ' ' + format;
  } else {
    return 'N/A';
  }
}

export const valuationDateDifference = (date, format) => {
  // Get Today's Date, format and split into array
  // const today = new Date();
  const currentDate = moment().format('DD/MM/YYYY');
  const [cDay, cMonth, cYear] = currentDate.split('/')
  var cDate = moment([cYear, cMonth, cDay], 'YYYY/MM/DD');

  // Get input Date, add 90 days and split into array.
  const [eDay, eMonth, eYear] = date.split('/')
  const actualMonth = String(eMonth - 1); // Java Date is 0 indexed (0-11)
  const valuationDate = moment([eYear, actualMonth, eDay]).add(90, 'days'); // Valuations expire in 90 days //!^* THE FOLLOWING STATEMENT NO LONGER APPLIES IT SEEMS ^*but need to add on an extra month (30 Days) to account for java 0 index

  if(!isNaN(valuationDate.diff(cDate, 'days'))) {
    if(valuationDate.diff(cDate, 'days') > 0) {
      return valuationDate.diff(cDate, 'days') + ' ' + format;
    } else {
      return 'Expired'
    }
  } else {
    return 'N/A';
  }
}

export const currencyFormatSecurityValues = (input, seperator) => {
  let formatValue = '';
  if(input && seperator)
  {
    let secValues = input.split(seperator);
    if(secValues && secValues.length > 0)
    {
      secValues.forEach((val) => {
        formatValue += currencyFormatterNoDecimals(val) + ' ' + seperator + ' ';
      });
      formatValue = formatValue.replace(/ ; $/, '')
    }
  }
  return formatValue;
};

export const transactionValues = (input) => {
  const amount = String(input).replace('-', '')
  const formatAmount = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: 'AUD',
    maximumFractionDigits: 2,
    roundingIncrement: 1,
  })

  return formatAmount.format(amount)
}