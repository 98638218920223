import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";

const exemptedRoutes = [
  '/404',
]

export function Timeout({ children } : { children: ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const timeoutRef = useRef();
  const [showTimeout, setShowTimeout] = useState(false)

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const handleWindowEvents = () => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setShowTimeout(true);
    }, (UserService._kc.idTokenParsed.exp - UserService._kc.idTokenParsed.iat) * 1000 ); // If token has expired (determined by KeyCloak Access Token Lifespan)
  }

  useEffect(() => {
    if(exemptedRoutes.includes(location.pathname)) return;

    window.addEventListener('mousemove', handleWindowEvents);
    window.addEventListener('keydown', handleWindowEvents);
    window.addEventListener('click', handleWindowEvents);
    window.addEventListener('scroll', handleWindowEvents);

    handleWindowEvents();

    return () => {
      window.removeEventListener('mousemove', handleWindowEvents);
      window.removeEventListener('keydown', handleWindowEvents);
      window.removeEventListener('click', handleWindowEvents);
      window.removeEventListener('scroll', handleWindowEvents);
    }
  }, [navigate, location.pathname])

  if(!showTimeout) {
    return children;
  } else {
    return (
      <>
        <div className="session-expired-overlay"></div>
        <div className="session-expired-warning-wrapper text-center">
        <h5>Session Expired</h5>
        <p>This session has been closed for your security. <br />Please login again.</p>
        <button type="button" className="btn px-6 py-2.5 rounded transition duration-150 ease-in-out" onClick={handleLogout}>Login</button>
        </div>
      </>
    )
  }
}