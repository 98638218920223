import React from 'react';
import UserService from "../../services/UserService";

function logout() {
  sessionStorage.clear()
  UserService.doLogout()
}

class LogoutButton extends React.Component {
  render() {
    return (
      <div className="w-1/2 lg:w-1/3 mx-1 md:mx-2 max-w-[160px]">
        <button className="btn red-btn logout-button w-full p-2 font-semibold" onClick={logout}>
          Logout
          <span className="logout-icon"></span>
        </button>
      </div>
    );
  }
}

export default LogoutButton;