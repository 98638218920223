import React, { useState, useRef } from "react";
import Cookies from 'universal-cookie';

import UserService from "./UserService";
import HttpService from "./HttpService";
import { showError } from '../toast';

export default function AuthenticatedRoute(props) {
  const numberOfDigits = 6
  const username = JSON.parse(sessionStorage.getItem('attributeData')).preferred_username
  const id = JSON.parse(sessionStorage.getItem('attributeData')).sub
  const cookies = new Cookies(null, { path: '/', maxAge: 1209600 }); // 14 (days) x 24 (hours) x 60 (minutes) x 60 (seconds)
  const [contact, setContact] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(cookies.get('verified') === id ? true : false);
  const [requested, setRequested] = useState(false)

  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);

  const censored = contact.length > 0 ? contact[0] + contact[1] + '*'.repeat(contact.length - 5) + contact.slice(-3) : null

  const requestVerification = async() => {
    setRequested(true)
    const data = await HttpService.post(
      `User/SendVerificationCode?realmName=${UserService._kc.realm}&userName=${username}&email=false&sms=true`
    );

    setContact(data)
  }

  const validateVerification = async() => {
    const joined = otp.join("")
    const response = await HttpService.get(
      `User/ValidateVerificationCode?realmName=${UserService._kc.realm}&userName=${username}&otp=${joined}`
    );

    if (response === "Validation Successful") {
      cookies.set('verified', id)
      setIsAuthenticated(true)

    } else {
      showError("There was an error, please try again later");
    }
  }

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if(value && index < numberOfDigits-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if(e.key === "Backspace" && !e.target.value && index > 0){
      otpBoxReference.current[index - 1].focus()
    }
    if(e.key === "Enter" && e.target.value && index < numberOfDigits-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  if(isAuthenticated) {
    return props.children
  } else {
    return (
      <div className="authenticate-wrapper py-4 px-6">
        <div className="">
          <h2 className="font-semibold mb-4">Authenticate</h2>
          {requested ?
            <div className="input-authentication">
              <p className="mb-4">A verification code has been sent to your contact number: {censored}</p>
              <div className="flex flex-row flex-wrap mb-4">
                {otp.map((digit, index)=>(
                  <input
                    key={index}
                    value={digit}
                    maxLength={1}
                    onChange={(e)=> handleChange(e.target.value, index)}
                    onKeyUp={(e)=> handleBackspaceAndEnter(e, index)}
                    ref={(reference) => (otpBoxReference.current[index] = reference)}
                    className="border w-1/6 m-2 p-2 flex-1 text-center rounded block focus:outline-[#ff0000] appearance-none"
                  />
                ))}
              </div>
              <button
                className="btn red-btn py-2 px-4"
                onClick={() => validateVerification()}
              >
                Verify
              </button>
            </div>
          :
            <div className="request-authentication">
              <p className="mb-2">You need to authenticate to proceed.</p>
              <button
                className="btn red-btn py-2 px-4"
                onClick={() => requestVerification()}
              >
                Request Verification Code
              </button>
            </div>
          }
        </div>
      </div>
    )
  }
}