// ClientDisableOptions.jsx
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import "./DisableOptions.scss";
import LoaderBar from "../shared/LoaderBar";
import HttpService from "../../services/HttpService";
import UserService from "../../services/UserService";
import ReasonModal from "./ReasonModal";
import ConfirmationModal from "./ConfirmationModal";

export default function OffsetAccountSettings() {
    const clientHash =
        sessionStorage.getItem("client") ?? UserService.getCustomerId();
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isUpdatingOption, setIsUpdatingOption] = useState(false);
    const [loanInfo, setLoanInfo] = useState([]);
    const [isReasonModalVisible, setIsReasonModalVisible] = useState(false);
    const [reasonText, setReasonText] = useState("");
    const [currentUpdateType, setCurrentUpdateType] = useState(null);
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const intervalIdRef = useRef(null);

    // Function to fetch loan information list
    const getLoanInformationList = useCallback(async () => {
        setIsFetchingData(true);
        setErrorMessage(""); // Clear any previous error messages
        try {
            const accountList = sessionStorage.getItem("accounts");
            if (!accountList) {
                throw new Error("No accounts found in session storage.");
            }

            let accountsJson;
            try {
                accountsJson = JSON.parse(accountList);
            } catch (parseError) {
                console.error(
                    "Error parsing accounts from session storage:",
                    parseError
                );
                throw new Error("Invalid accounts data.");
            }

            const accountHashes = accountsJson.map((account) => account.AccountHash);
            if (!accountHashes || accountHashes.length === 0) {
                throw new Error("No account hashes available.");
            }

            const accountHashesString = accountHashes.join(",");
            const data = await HttpService.post(
                `LoanInformationList?clientHash=${clientHash}&loanNumbers=${accountHashesString}`
            );

            if (!data) {
                throw new Error("Received empty data from LoanInformationList API.");
            }

            setLoanInfo(data);
            setErrorMessage(""); // Clear error message on success
        } catch (error) {
            console.error("Error fetching loan information:", error);
            setErrorMessage(
                "There was an error fetching loan information. Please try again later."
            );
        } finally {
            setIsFetchingData(false);
        }
    }, [clientHash]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                // Clear any existing interval
                if (intervalIdRef.current) {
                    clearInterval(intervalIdRef.current);
                }
                // Start the interval
                getLoanInformationList(); // Fetch immediately when page becomes visible
                intervalIdRef.current = setInterval(() => {
                    getLoanInformationList();
                }, 20000); // 20 seconds
            } else {
                // Page is not visible, clear the interval
                if (intervalIdRef.current) {
                    clearInterval(intervalIdRef.current);
                    intervalIdRef.current = null;
                }
            }
        };

        // Set up the event listener
        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Check immediately in case the page is already visible
        handleVisibilityChange();

        return () => {
            // Cleanup
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, [getLoanInformationList]);

    // Function to update account settings
    const updateAccountSettings = async (type, reason) => {
        setIsUpdatingOption(true);
        setErrorMessage(""); // Clear any previous error messages

        const accountsToProcess =
            type === "redrawRestricted"
                ? accountsWithInstantTransactionFalse
                : accountsWithAnybodyToSignTrue;

        if (!accountsToProcess || accountsToProcess.length === 0) {
            setErrorMessage("No accounts available to update for this option.");
            setIsUpdatingOption(false);
            return;
        }

        try {
            const updatePromises = accountsToProcess.map((account) =>
                HttpService.post(
                    `Offset/UpdateOffsetAccountSettings?clientHash=${clientHash}&AccountHash=${account.AccountHash
                    }&requestType=${type}&reason=${encodeURIComponent(reason)}`
                )
            );

            await Promise.all(updatePromises);
            alert("Option disabled successfully.");
            setErrorMessage(""); // Clear error message on success
            // Refresh the loan information
            getLoanInformationList();
        } catch (error) {
            console.error("Error disabling option:", error);
            setErrorMessage(
                "There was an error disabling the option: " + (error.message || error)
            );
        } finally {
            setIsUpdatingOption(false);
            setReasonText("");
            setCurrentUpdateType(null);
        }
    };

    // Get accounts with InstantTransactionStatus as false
    const accountsWithInstantTransactionFalse = useMemo(() => {
        return (
            loanInfo?.filter(
                (account) => account?.InstantTransactionStatus === false
            ) || []
        );
    }, [loanInfo]);

    // Get accounts with AnybodyToSignStatus as true
    const accountsWithAnybodyToSignTrue = useMemo(() => {
        return (
            loanInfo?.filter((account) => account?.AnybodyToSignStatus === true) || []
        );
    }, [loanInfo]);

    // Compute options based on the above arrays
    const options = useMemo(() => {
        const opts = [];
        if (accountsWithInstantTransactionFalse.length > 0) {
            opts.push({
                id: 1,
                name: "Disable Instant Transactions",
                type: "redrawRestricted",
            });
        }
        if (accountsWithAnybodyToSignTrue.length > 0) {
            opts.push({
                id: 2,
                name: "Disable Anybody to Sign",
                type: "authoriseAnyone",
            });
        }
        return opts;
    }, [accountsWithInstantTransactionFalse, accountsWithAnybodyToSignTrue]);

    // Function to get the confirmation message based on the action
    const getConfirmationMessage = () => {
        if (currentUpdateType === "authoriseAnyone") {
            return "If you proceed with this request all customers on the loan will be required to authorise every transaction in RedZed Connect";
        } else if (currentUpdateType === "redrawRestricted") {
            return "If you proceed with this request you will no longer be able to access your funds instantly via RedZed Connect";
        }
        return "";
    };

    // Handle disabling an option
    const disableOption = (type) => {
        setCurrentUpdateType(type);
        setIsReasonModalVisible(true);
    };

    const handleReasonSubmit = () => {
        if (!reasonText.trim()) {
            setErrorMessage("Please enter a reason.");
            return;
        }
        setIsReasonModalVisible(false);
        setIsConfirmationVisible(true);
    };

    const handleReasonCancel = () => {
        setIsReasonModalVisible(false);
        setReasonText("");
        setCurrentUpdateType(null);
        setErrorMessage(""); // Clear any error messages
    };

    const handleConfirmationProceed = () => {
        setIsConfirmationVisible(false);
        updateAccountSettings(currentUpdateType, reasonText);
    };

    const handleConfirmationCancel = () => {
        setIsConfirmationVisible(false);
        setReasonText("");
        setCurrentUpdateType(null);
        setErrorMessage(""); // Clear any error messages
    };

    if (isUpdatingOption) {
        return <LoaderBar />;
    }

    return (
        <div className="md:container mx-auto px-2 lg:px-0">
            <div className="disable-options-wrapper">
                <h1>Disable Options</h1>
                <p>
                    Select the options you want to disable. You will be asked to provide a
                    reason for disabling each option.
                </p>

                {/* Display error message if any */}
                {errorMessage && (
                    <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-4">
                        {errorMessage}
                    </div>
                )}

                <div className="options-table-container overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Option Name
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {options.length > 0 ? (
                                options.map((option) => (
                                    <tr key={option.id}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {option.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button
                                                className="disable-btn"
                                                onClick={() => disableOption(option.type)}
                                            >
                                                Disable Option
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="2" className="px-6 py-4 text-center">
                                        No options available to disable.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="options-grid-footer mt-4">
                        <p className="pt-6 italic">
                            <small>Please contact support if you need assistance.</small>
                        </p>
                    </div>
                </div>
            </div>
            <ReasonModal
                visible={isReasonModalVisible}
                onSubmit={handleReasonSubmit}
                onCancel={handleReasonCancel}
                reasonText={reasonText}
                setReasonText={setReasonText}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
            />
            <ConfirmationModal
                visible={isConfirmationVisible}
                onProceed={handleConfirmationProceed}
                onCancel={handleConfirmationCancel}
                message={getConfirmationMessage()}
            />
        </div>
    );
}
