import Keycloak from "keycloak-js";
import { toPascalCase } from "../Utils";

const _kc = new Keycloak({
  url: process.env.REACT_APP_AUTH_SERVER,
  realm: process.env.REACT_APP_REALM,
  clientId: "react-app"
});

const doLogin = () => _kc.login({redirectUri: process.env.REACT_APP_REDIRECT_URL});

const doLogout = () => _kc.logout({redirectUri: process.env.REACT_APP_REDIRECT_URL});

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateRefreshToken = () => _kc.updateToken(720).then().catch(doLogin);

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getCustomerId = () => _kc.tokenParsed?.customerid;

const isAdmin = () => _kc.hasRealmRole("Administrators");

const isCustomer = () => _kc.hasRealmRole("Customer")

const isIntroducer = () => _kc.hasRealmRole("Introducer");

const isMasterIntroducer = () => _kc.hasRealmRole("MasterIntroducer");

const isBDM = () => _kc.hasRealmRole("BDM")

const isAggregator = () => _kc.hasRealmRole("Aggregator BDM");

const isSuperMasterIntroducer = () => _kc.hasRealmRole("SuperMasterIntroducer");

const isSolicitor = () => _kc.hasRealmRole("Solicitor");

const isMasterSolicitor = () => _kc.hasRealmRole("Mastersolicitor");

const isRZMasterSolicitor = () => _kc.hasRealmRole("RZMasterSolicitor");

const isClientServices = () => _kc.hasRealmRole("RZCustService");

const getUserFullname = () =>
  (
    toPascalCase(_kc.tokenParsed?.given_name) +
    " " +
    toPascalCase(_kc.tokenParsed?.family_name)
  )?.trimEnd();

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "login-required",
      silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256"
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    });
};


const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUserFullname,
  getCustomerId,
  isAdmin,
  isCustomer,
  isIntroducer,
  isMasterIntroducer,
  isBDM,
  isAggregator,
  isSuperMasterIntroducer,
  isSolicitor,
  isMasterSolicitor,
  isRZMasterSolicitor,
  isClientServices,
  _kc,
  updateRefreshToken,
};

export default UserService;
